define("insights-web/components/realtime-widget", ["exports", "insights-web/mixins/widget-mixin", "insights-web/utils/colorDefaults"], function (_exports, _widgetMixin, _colorDefaults) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TimeRegex = /^\d\d:\d\d:\d\d$/gm;
  var _default = _exports.default = Ember.Component.extend(_widgetMixin.default, {
    widgetStateService: Ember.inject.service(),
    realtimeService: Ember.inject.service(),
    requestService: Ember.inject.service(),
    statCalculation: Ember.inject.service(),
    timeDiff: Ember.inject.service(),
    lastUpdateTime: null,
    forceRefresh: null,
    data: [],
    _updateAlert: function _updateAlert() {
      var alerts = this.configuration.alerts;
      if (typeof alerts === "undefined" || alerts === null) {
        return;
      }
      var value = this.get("data");
      this.set("alertColor", null);
      if (this.configuration.useCustomBackgroundColor) {
        this.$().parents('.grid-stack-item-content').css('background-color', this.configuration.customBackgroundColor);
      } else {
        this.$().parents('.grid-stack-item-content').css('background-color', '');
      }
      if (this.configuration.useCustomTextColor) {
        this.set("titleColor", this.configuration.customTextColor);
      }
      if (value && value.match && value.match(TimeRegex)) {
        var parts = value.split(":");
        var seconds = parseInt(parts[2]);
        var minutesToSeconds = parts[1] * 60;
        var hoursToSeconds = parts[0] * 60 * 60;
        value = seconds + minutesToSeconds + hoursToSeconds;
      }
      for (var x = 0; x < alerts.length; x++) {
        var evalAlert = alerts[x];
        if (value >= evalAlert.min && value < evalAlert.max) {
          this.set("alertColor", evalAlert.color);
          if (this.configuration.invertAlertColors == true) {
            this.set("textColor", evalAlert.color);
          } else {
            this.$().parents('.grid-stack-item-content').css('background-color', evalAlert.color);
            var shaded = this._shadeColor(evalAlert.color.substring(1), -10);
            this.$().parents('.grid-stack-item-content').css('border', '1px solid ' + shaded);
          }
        }
      }
    },
    _shadeColor: function _shadeColor(color, percent) {
      var f = parseInt(color.slice(1), 16),
        t = percent < 0 ? 0 : 255,
        p = percent < 0 ? percent * -1 : percent,
        R = f >> 16,
        G = f >> 8 & 0x00FF,
        B = f & 0x0000FF;
      return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    },
    valuesChanged: Ember.observer('data', function () {
      this._updateAlert();
      this._setFontSizeSoon();
      //this.dataCalculate();
    }),
    updateTimers: function updateTimers() {
      if (window.debug === true) {
        this.set("isDebugMode", true);
      }
      if (this && !this.isDestroyed) {
        Ember.run.later(this, function () {
          this.updateTimers();
        }, 1000); //every second
      }
      this.updateTimersImpl();
    },
    updateTimersImpl: function updateTimersImpl() {
      if (!this || this.isDestroyed) {
        return;
      }
      var isTimeSince = this.get("isTimeSince");
      if (isTimeSince === true) {
        var now = moment();
        var data = this.get("data");
        if (typeof data !== "undefined" && data !== "-") {
          if (now.isAfter(this.get("startTime"))) {
            if (!this.isDestroyed) {
              // let diff = now - this.get("startTime");

              var diff = moment.duration(now.diff(this.get("startTime")));
              if (diff.seconds() > -1) {
                // this.set("data", moment.utc(diff).format("HH:mm:ss"));
                this.set("data", this.getTimeFormattedString(this.get("startTime"), now));
              }
            }
          }
        } else {
          this.set("data", '-');
        }
      }
    },
    getTimeFormattedString: function getTimeFormattedString(start, end) {
      start = this.get("timeDiff").skewAdjustedTime(start);
      var diff = moment.duration(end.diff(start));

      //Get hours and subtract from duration
      var hours = diff.hours();
      diff.subtract(moment.duration(hours, 'hours'));
      if (diff.days() > 0) {
        hours = hours + 24 * diff.days();
      }

      //Get Minutes and subtract from duration
      var minutes = diff.minutes();
      diff.subtract(moment.duration(minutes, 'minutes'));

      //Get seconds
      var seconds = diff.seconds();
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (hours < 10) {
        hours = "0" + hours;
      }
      return "".concat(hours, ":").concat(minutes, ":").concat(seconds);
    },
    dataCalculate: function () {
      var statService = this.get("statCalculation");
      try {
        this.set("isTime", false);
        if (typeof this.configuration === 'undefined' || this.configuration === null) {
          return;
        }
        var queues = this.configuration.queueIds;
        if (this.configuration.campaignId) {
          queues = [this.configuration.campaignId];
        }
        var startTime = moment().startOf('day').utc().toISOString();
        if (this.configuration.timePeriod === "currentinterval") {
          startTime = moment().startOf('hour').utc();
          if (moment().minute() >= 30) {
            startTime = startTime.add('30', 'm');
          }
          startTime = startTime.toISOString();
        } else if (this.configuration.timePeriod === "previousinterval") {
          startTime = moment().startOf('hour').utc();
          if (moment().minute() <= 30) {
            startTime = startTime.subtract('30', 'm');
          }
          startTime = startTime.toISOString();
        }
        var data = statService.getStatValue(queues, this.configuration.type, startTime, this.configuration.mediaType);
        if (data.isTimeSince === true) {
          if (data.data) {
            // let diff = moment() - data.data;
            this.set("startTime", data.data);
            // data.data = moment.utc(diff).format("HH:mm:ss");
            data.data = this.getTimeFormattedString(data.data, moment());
          } else {
            data.data = '-';
          }
        }
        this.set('data', data.data);
        this.set("isTime", data.isTime);
        this.set("isTimeSince", data.isTimeSince);
        this.set("showPercent", data.showPercent);
        this.set("lastDataUpdate", data.update);
        this.set('lastUpdateTime', moment().format());
        if (this.configuration.type === "conversations_active") {
          var value = data.data;
          if (value === 0 && this.configuration.zeroTimer === true) {
            var state = this.get("widgetStateService").getState(this.get("dashboardIndex"), this.get("widgetIndex"));
            if (state === null) {
              state = moment();
              this.get("widgetStateService").setState(this.get("dashboardIndex"), this.get("widgetIndex"), state);
            }
          } else {
            this.set("secondaryData", null);
          }
        } else if (this.configuration.type === "queue_conversation_state_by_mediatype") {
          this.options.isStacked = true;
          this.options.bars = 'horizontal';
          this.options.series = {
            0: {
              color: '#d9534f'
            },
            1: {
              color: '#2E75B6'
            }
          };
        } else if (this.configuration.type === "queue_mediatype") {
          this.options.series = {
            0: {
              color: '#d9534f'
            },
            1: {
              color: '#2E75B6'
            },
            2: {
              color: 'blue'
            }
          };
        }
      } catch (ex) {
        Ember.Logger.error(ex);
        this.get("requestService").logError("dataCalculate: " + ex.toString());
      }
      if (this && !this.isDestroyed) {
        if (this.forceRefresh) {
          Ember.run.cancel(this.forceRefresh);
        }
        var forceRefreshLater = Ember.run.later(this, function () {
          this.dataCalculate();
        }, 10000);
        this.set("forceRefresh", forceRefreshLater);
      }
    }.observes('realtimeService.queueData'),
    restimeServiceObservationDataChanged: function restimeServiceObservationDataChanged() {
      var ob = this.get('realtimeService').get("observationData");
      this.set("observationData", ob);
      this.dataCalculate();
      this._updateAlert();
    },
    init: function init() {
      this._super.apply(this, arguments);
      var self = this;
      this.get('realtimeService').on('observationData', this, this.restimeServiceObservationDataChanged);
      self.dataCalculate();
      this._setFontSizeSoon();
      Ember.$(window).on('resize', Ember.run.bind(this, this._setFontSizeSoon));
      this.updateTimers();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      try {
        this._updateAlert();
      } catch (ex) {}
    },
    alertsObserver: Ember.observer('configuration', 'configuration.alerts', 'configuration.alerts.@each', function () {
      this._updateAlert();
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('realtimeService').off('observationData', this, this.restimeServiceObservationDataChanged);
    },
    options: {
      backgroundColor: '#fff',
      annotations: {
        alwaysOutside: true
      }
    }
  });
});