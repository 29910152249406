define("insights-web/services/realtime-service", ["exports", "insights-web/utils/widgetstattypes", "insights-web/utils/agentstattypes", "ember-cli-guid", "insights-web/helpers/html-string"], function (_exports, _widgetstattypes, _agentstattypes, _emberCliGuid, _htmlString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import moment from '../../node_modules/moment/moment';

  var POLLING_INTERVAL = 8000;
  var LONG_POLLING_INTERVAL = 45000;
  var REALTIME_GATED_UPDATE = 1000;
  var OBSERVATION_DATA_POLL = 60000;
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    cookies: Ember.inject.service(),
    pollingInterval: POLLING_INTERVAL,
    requestService: Ember.inject.service(),
    missingPermissionService: Ember.inject.service(),
    widgetStateService: Ember.inject.service(),
    queueService: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    ajax: Ember.inject.service(),
    purecloud: Ember.inject.service(),
    errorService: Ember.inject.service(),
    _isPollingHistorical: false,
    gatedObservationData: {},
    observationData: {},
    userConversations: {},
    userRoutingStatus: {},
    notificationSubscriptions: [],
    lastUpdateTimes: {},
    notificationHistory: [],
    queueAggregateHistory: [],
    _queueUserCache: {},
    lastWebSocketNotification: null,
    startPollingTime: null,
    websocketDetails: {},
    init: function init() {
      this._super.apply(this, arguments);
      this.checkWebsocketStatus();
      this.pollDashboards();
    },
    pollDashboards: function pollDashboards() {
      var _this = this;
      //if headless, poll for updates
      var cookieService = this.get('cookies');
      var cookie = JSON.parse(cookieService.read("purecloudTokenV2"));
      if (cookie["pureinsightsHeadless"]) {
        Ember.run.later(function () {
          _this.getDashboards().catch(function (err) {
            Ember.Logger.error(err);
            if (err.payload && err.payload.error === "invalid permalink") {
              window.location.replace('/loginerror.html?error=Invalid Permalink');
              return;
            }
          });
          _this.pollDashboards();
        }, 60000);
      }
    },
    checkWebsocketStatus: function checkWebsocketStatus() {
      var _this2 = this;
      Ember.run.later(function () {
        var lastWebSocketNotification = _this2.get("lastWebSocketNotification");
        var lastAcceptableTime = moment().subtract(1.25, 'minutes');
        var isValid = lastWebSocketNotification > lastAcceptableTime;
        if (!isValid) {
          _this2.set("lastWebSocketNotification", moment());
          _this2.startPolling();
        }
        _this2.checkWebsocketStatus();
      }, 61000);
    },
    queryExistingConversationsForUsers: function queryExistingConversationsForUsers(userIds) {
      var _this3 = this;
      if (userIds.length === 0) {
        return;
      }

      // https://developer.mypurecloud.com/forum/t/limit-to-the-number-of-query-predicates-for-analytics/7791
      // need to limit predicates, since we only get 50 conversations, we can limit the query to 175 users
      if (userIds.length > 175) {
        userIds = userIds.slice(0, 175);
      }
      var self = this;
      var start = moment().subtract(1, 'd').utc();
      var end = moment().utc();
      var query = {
        "interval": start.utc().toISOString() + "/" + end.utc().toISOString(),
        "order": "asc",
        "orderBy": "conversationStart",
        "paging": {
          "pageSize": 100,
          "pageNumber": 1
        },
        "segmentFilters": [{
          "clauses": [{
            "predicates": [],
            "type": "or"
          }],
          "predicates": [{
            "type": "dimension",
            "dimension": "segmentType",
            "operator": "matches",
            "value": "interact"
          }],
          "type": "and"
        }],
        "conversationFilters": [{
          "type": "or",
          "predicates": [{
            "type": "dimension",
            "dimension": "conversationEnd",
            "operator": "notExists",
            "value": null
          }]
        }]
      };
      userIds.forEach(function (u) {
        query.segmentFilters[0].clauses[0].predicates.push({
          "type": "dimension",
          "dimension": "userId",
          "operator": "matches",
          "value": u
        });
      });
      var analytics = this.get("purecloud").getAnalyticsApi();

      // Run this a little later so that on startup, if we get rate limited, these are a lower priority 
      Ember.run.later(function () {
        analytics.postConversationsDetailsQuery(query).then(function (conversations) {
          var conversationCount = 0;
          var conversationIds = [];
          conversations.conversations.forEach(function (conversation) {
            conversation.participants.forEach(function (p) {
              if (userIds.indexOf(p.userId) > -1) {
                conversationIds.pushObject({
                  conversationId: conversation.conversationId,
                  userId: p.userId
                });
              }
            });
          });

          //Limit conversation IDS to 50 to avoid API abuse
          if (conversationIds.length > 50) {
            // because we limit the above to 50 calls, we might not properly reset acw status, so lets force an acw reset here
            var userConversations = _this3.get("userConversations");
            Object.keys(userConversations).forEach(function (conv) {
              conv.isAcw = false;
            });
            _this3.set("userConversations", userConversations);
            conversationIds = conversationIds.slice(0, 50);
          }
          conversationIds.forEach(function (conversation) {
            self.get("purecloud").getRequest("/api/v2/conversations/".concat(conversation.conversationId)).then(function (c) {
              self.processConversation(conversation.userId, c);
            });
          });
        });
      }, 1250);
    },
    getConversationSummary: function getConversationSummary(dimension, count, start, end, queues, conversationCount) {
      var analytics = this.get("purecloud").getAnalyticsApi();
      var queueFilter = [];
      if (!count) {
        count = "10";
      }
      queues.forEach(function (id) {
        queueFilter.push({
          "type": "dimension",
          "dimension": "queueId",
          "operator": "matches",
          "value": id.trim()
        });
      });
      var requestData = {
        interval: start.utc().toISOString() + "/" + end.utc().toISOString(),
        paging: {
          pageSize: conversationCount,
          pageNumber: 1
        },
        segmentFilters: [{
          type: "or",
          predicates: queueFilter
        }],
        aggregations: [{
          type: "termFrequency",
          dimension: dimension,
          size: count
        }]
      };
      return analytics.postConversationsDetailsQuery(requestData);
    },
    getAggregateData: function getAggregateData(stat, start, end, queues, granularity, groupBy) {
      var _this4 = this;
      var analytics = this.get("purecloud").getAnalyticsApi();
      var queueFilter = [];
      queues.forEach(function (id) {
        queueFilter.push({
          "type": "dimension",
          "dimension": "queueId",
          "operator": "matches",
          "value": id.trim()
        });
      });
      var requestData = {
        interval: start.utc().toISOString() + "/" + end.utc().toISOString(),
        groupBy: groupBy || ["queueId"],
        metrics: [stat],
        filter: {
          type: "or",
          predicates: queueFilter
        },
        granularity: granularity
      };
      // debugger;
      return analytics.postConversationsAggregatesQuery(requestData).then(function (resp) {
        _this4.queueAggregateHistory.pushObject({
          time: moment().format(),
          request: requestData,
          response: resp
        });
        if (_this4.queueAggregateHistory.length > 50) {
          _this4.queueAggregateHistory.removeAt(0);
        }
        return resp;
      }).catch(function (err) {
        _this4.queueAggregateHistory.pushObject({
          time: moment().format(),
          request: requestData,
          error: err
        });
        if (_this4.queueAggregateHistory.length > 50) {
          _this4.queueAggregateHistory.removeAt(0);
        }
        return err;
      });
    },
    dashboardConfiguration: {
      dashboards: [{
        dashboardName: "Default Dashboard",
        widgets: [],
        allowresize: true,
        id: _emberCliGuid.default.create()
      }]
    },
    _getWidgetConfiguration: function _getWidgetConfiguration(typeName) {
      var returnwidget = null;
      _widgetstattypes.default.forEach(function (widget) {
        if (widget.key === typeName) {
          returnwidget = widget;
        }
      });
      return returnwidget;
    },
    subscribeToTopicsWithRetry: function subscribeToTopicsWithRetry(channelId) {
      var _this5 = this;
      var self = this;
      var notificationsapi = this.get("purecloud").getNotificationsApi();
      var topics = [];
      this.websocketDetails.topicLength = self.notificationSubscriptions.length;
      self.notificationSubscriptions.forEach(function (topic) {
        if (topics.length < 975) {
          topics.push({
            id: topic
          });
        }
      });
      if (topics.length === 0) {
        return;
      }
      if (topics.length > 975) {
        console.error("Maximum number of subscriptions reached from subscribeToTopics");
        self.get("requestService").logError("Maximum number of subscriptions reached from subscribeToTopics" + JSON.stringify(topics));
        self.get('notifications').error('Maximum number of subscriptions reached. Genesys Cloud limits the number of statistic changes that can be registered to listen for at a time, some statistics might have a delay in being updated. Generally this becomes an issue when watching agent stats on large queues.', {
          autoClear: false,
          clearDuration: 1200
        });
      }
      notificationsapi.putChannelsChannelIdSubscriptions(channelId, topics).then(function () {
        console.log("subscribed to notification topics");
        _this5.websocketDetails.subscribedTime = moment();
        _this5.websocketDetails.subscribeError = "";
      }).catch(function (ex) {
        Ember.Logger.error("put channel subscriptions err ", ex);
        self.get("requestService").logError("post channel subscriptions err " + ex.toString());
        var nextAttempt = 3000;
        self.websocketDetails.subscribeError = ex.statusCode + " " + ex.toString();
        if (ex.statusCode === 400) {
          //channel most likely expired
          _openWebSocket();
          return;
        } else if (ex.statusCode === 429) {
          if (ex.headers['retry-after']) {
            nextAttempt = parseInt(ex.headers['retry-after']);
            if (!nextAttempt || nextAttempt === 0 || isNaN(nextAttempt)) {
              nextAttempt = 60;
            }
          } else {
            nextAttempt = 60;
          }
          nextAttempt = nextAttempt * 1000;
        }
        console.log("retrying in " + nextAttempt);
        Ember.run.later(function () {
          self.subscribeToTopicsWithRetry(channelId);
        }, nextAttempt);
      });
    },
    _openWebSocket: function _openWebSocket() {
      this.set("webSocketOpenTime", moment());
      var self = this;
      var websocket = this.get("websocket");

      // if(websocket){
      //     debugger;
      //     try{
      //         Ember.Logger.warn("closing websocket")
      //         websocket.close();
      //         this.set("websocket", null);
      //         websocket = null;
      //     }
      //     catch(ex){Ember.Logger.warn(ex)}
      // }

      function subscribeToTopics() {
        var channelId = self.get("websocketChannelId");
        self._getQueueIdsFromDashboards().forEach(function (id) {
          var topic = "v2.analytics.queues.".concat(id, ".observations");
          if (self.notificationSubscriptions.indexOf(topic) === -1) {
            self.notificationSubscriptions.push(topic);
            self.notificationSubscriptions.push(topic + '.details');
          }
        });
        self._getCampaignIdsFromDashboards().forEach(function (id) {
          var topic = "v2.outbound.campaigns.".concat(id, ".stats");
          if (self.notificationSubscriptions.indexOf(topic) === -1) {
            self.notificationSubscriptions.push(topic);
          }
        });
        self.subscribeToTopicsWithRetry(channelId);
        Ember.Logger.log("websocket open");
        self.getUserData();
      }
      var notificationsapi = this.get("purecloud").getNotificationsApi();
      if (websocket) {
        subscribeToTopics();
      } else {
        notificationsapi.postChannels().then(function (data) {
          try {
            self.getObservationData();
            websocket = new WebSocket(data.connectUri);
            Ember.Logger.log("Opening web socket " + moment().format());
            self.set("websocketChannelId", data.id);
            websocket.onopen = function () {
              subscribeToTopics();
              self.websocketDetails.openTime = moment();
              self.websocketDetails.openError = "";
            };
            websocket.onclose = function (event) {
              self.websocketDetails.onCloseTime = moment();
              self.websocketDetails.onCloseError = "Websocket onclose code ".concat(event.code, " - ").concat(event.reason, " ").concat(moment().format());
              if (event.code !== 1000) {
                Ember.Logger.error("Websocket onclose code ".concat(event.code, " - ").concat(event.reason, " ").concat(moment().format()));
              }
              self.set("websocket", null);
              self._openWebSocket();
              websocket.onerror = function () {};
            };
            websocket.onerror = function (event) {
              self.websocketDetails.onErrorTime = moment();
              self.websocketDetails.onErrorError = "Websocket onerror " + event;
              Ember.Logger.error("Websocket onerror " + moment().format());
              self.set("websocket", null);
              Ember.run.later(function () {
                self._openWebSocket();
              }, 1500);
              websocket.code = function () {};
            };
            websocket.onmessage = function (message) {
              try {
                self.set("lastWebSocketNotification", moment());
                var event = JSON.parse(message.data);
                self.websocketDetails.lastWebSocketNotification = moment();
                if (window.location.host === "localhost:4200") {
                  console.log(event.topicName);
                }

                // console.log(event);
                self.notificationHistory.pushObject({
                  event: event,
                  time: moment().format(),
                  uri: data.connectUri
                });
                while (self.notificationHistory.length > 100) {
                  self.notificationHistory.shiftObject();
                }
                if (event.topicName.indexOf('message') === -1) {
                  self.lastUpdateTimes[event.topicName] = moment().format();
                  var _data = event.eventBody;
                  var observationData = self.get("gatedObservationData");
                  var group = _data.mediaType;
                  if (_data.group && _data.group.mediaType) {
                    group = _data.group.mediaType;
                  }
                  if (!group) {
                    group = "queue";
                  }
                  if (event.topicName.indexOf('v2.outbound.campaigns') !== -1) {
                    if (_data.group.outboundCampaignId) {
                      group = "campaignId"; // data.group.outboundCampaignId;
                    }
                    if (!observationData[_data.group.outboundCampaignId]) {
                      observationData[_data.group.outboundCampaignId] = {};
                    }
                    if (!observationData[_data.group.outboundCampaignId][group]) {
                      observationData[_data.group.outboundCampaignId][group] = {};
                    }
                    for (var x = 0; x < _data.data.length; x++) {
                      for (var y = 0; y < _data.data[x].metrics.length; y++) {
                        var metric = _data.data[x].metrics[y];
                        observationData[_data.group.outboundCampaignId][group][metric.metric] = metric.stats;
                      }
                    }
                  } else if (event.topicName.indexOf('v2.analytics.queues') > -1 && event.topicName.indexOf('.details') > -1) {
                    _data.results.forEach(function (d) {
                      if (d.group.mediaType) {
                        group = d.group.mediaType;
                      } else {
                        group = "queue";
                      }
                      if (!d.group.queueId) {
                        d.group.queueId = event.topicName.split('.')[3]; //get queue Id
                      }
                      if (!observationData[d.group.queueId]) {
                        observationData[d.group.queueId] = {};
                      }
                      if (!observationData[d.group.queueId][group]) {
                        observationData[d.group.queueId][group] = {};
                      }
                      var metric = d.data;
                      var key = metric.metric;
                      if (metric.qualifier) {
                        key = "".concat(key, ".").concat(metric.qualifier);
                      }
                      var value = metric.stats;
                      if (value) {
                        value.receivedBy = "websocket";
                        value.receivedAt = moment().format();
                        observationData[d.group.queueId][group][key] = value;
                      } else {
                        observationData[d.group.queueId][group][key] = {};
                      }
                      if (metric.observations) {
                        metric.observations.receivedBy = "websocket";
                        metric.observations.receivedAt = moment().format();
                        observationData[d.group.queueId][group][key + "Observations"] = metric.observations;
                      } else {
                        observationData[d.group.queueId][group][key + "Observations"] = [];
                      }
                    });
                  } else if (event.topicName.indexOf('v2.analytics.queues') > -1) {
                    if (!observationData[_data.group.queueId]) {
                      observationData[_data.group.queueId] = {};
                    }
                    if (!observationData[_data.group.queueId][group]) {
                      observationData[_data.group.queueId][group] = {};
                    }
                    _data.data.forEach(function (dataitem) {
                      dataitem.metrics.forEach(function (metric) {
                        var key = metric.metric;
                        if (metric.qualifier) {
                          key = "".concat(key, ".").concat(metric.qualifier);
                        }
                        var value = metric.stats;
                        if (value) {
                          value.receivedBy = "websocket";
                          value.receivedAt = moment().format();
                          observationData[_data.group.queueId][group][key] = value;
                        }
                      });
                    });
                  } else if (event.topicName.indexOf("presence") > -1) {
                    var userId = event.topicName.split('.')[2];
                    var userPresenceMap = self.get("userPresence");
                    if (!userPresenceMap) {
                      console.error("User presence event before map set - " + message.data);
                      return;
                    }
                    var presenceInfo = JSON.parse(JSON.stringify(userPresenceMap));
                    _data.receivedBy = "websocket";
                    _data.receivedAt = moment().format();
                    presenceInfo[userId] = _data;
                    self.set("userPresence", presenceInfo);
                  } else if (event.topicName.indexOf("routingStatus") > -1) {
                    var _userId = _data.id;
                    var routingStatus = JSON.parse(JSON.stringify(self.get("userRoutingStatus")));
                    _data.routingStatus.receivedBy = "websocket";
                    _data.routingStatus.receivedAt = moment().format();
                    routingStatus[_userId] = _data.routingStatus;
                    self.set("userRoutingStatus", routingStatus);
                  } else if (event.topicName.indexOf("outofoffice") > -1) {
                    var _userId2 = _data.user.id;
                    var ooo = JSON.parse(JSON.stringify(self.get("userIsOOO")));
                    ooo[_userId2] = _data.active;
                    self.set("userIsOOO", ooo);
                  } else if (event.topicName.indexOf('v2.system.no_longer_subscribed') === 0) {
                    console.log("no longer subscribed to websocket, resubscribing");
                    self._openWebSocket();
                  } else if (event.topicName.indexOf("v2.users") > -1 && event.topicName.indexOf("conversations") > -1) {
                    var _userId3 = event.topicName.split('.')[2];
                    _data.updateBy = "websocket";
                    _data.updateTime = moment().format();
                    self.processConversation(_userId3, _data);
                  }
                  self.set("gatedObservationData", observationData);
                }
              } catch (ex) {
                self.websocketDetails.lastWebSocketNotificationError = ex.toString();
                self.websocketDetails.lastWebSocketNotificationErrorTime = moment();
                if (message) {
                  console.error("error handling websocket message " + ex.toString() + " message - " + message.data);
                } else {
                  console.error("error handling websocket message " + ex.toString() + " message - is undefined");
                }
              }
            };
            self.set("websocket", websocket);
          } catch (ex) {
            var err = new Error();
            console.log("exception caught subscribing to websocket " + ex.toString() + " " + err.stack);
            self.get("requestService").logError("exception caught subscribing to websocket " + ex.toString() + " " + err.stack);
            self.websocketDetails.openErrorTime = moment();
            self.websocketDetails.openError = "exception caught subscribing to websocket " + ex.toString() + " " + err.stack;
          }
        }).catch(function (ex) {
          var err = new Error();
          console.log("Unable to open websocket channel " + ex.toString() + " " + err.stack);
          self.get("requestService").logError("Unable to open websocket channel " + ex.toString() + " " + err.stack);
          self.websocketDetails.openErrorTime = moment();
          self.websocketDetails.openError = "Unable to open websocket channel " + ex.toString() + " " + err.stack;
          var nextAttempt = 60000;
          if (ex.statusCode === 429) {
            // debugger;
            if (ex.headers['retry-after']) {
              nextAttempt = parseInt(ex.headers['retry-after']);
              if (!nextAttempt || nextAttempt === 0 || isNaN(nextAttempt)) {
                nextAttempt = 60;
              }
            } else {
              nextAttempt = 60;
            }
            nextAttempt = nextAttempt * 1000;
          }
          Ember.run.later(function () {
            self._openWebSocket();
          }, nextAttempt);
        });
      }
    },
    processConversation: function processConversation(userId, conversation) {
      var userConversations = this.get("userConversations");
      var details = userConversations[userId] || {
        conversations: {},
        isAcw: false,
        acwStartTime: null,
        longestActiveStart: null,
        conversationCount: 0
      };
      details.isAcw = false;
      details.acwStartTime = null;
      conversation.participants.forEach(function (p) {
        if (p.userId === userId) {
          if (!p.wrapupTimeoutMs) {
            p.wrapupTimeoutMs = 0;
          }
          if ((!p.startAcwTime || p.wrapup && p.wrapup.code !== "" || p.wrapupTimeoutMs && moment.utc(p.startAcwTime).add(p.wrapupTimeoutMs, 'ms').isBefore(moment.utc()) || p.endAcwTime && moment.utc(p.endAcwTime).isBefore(moment.utc())) && p.endTime) {
            delete details.conversations[conversation.id];
          } else {
            details.conversations[conversation.id] = p;
          }
        }
      });
      var oldestAcw = null;
      var oldestAcwId = "";
      var oldestAcwStringValue = "";
      var isAcw = false;
      var longestActive = new Date();
      Object.keys(details.conversations).forEach(function (id) {
        var call = details.conversations[id];
        if (call.calls) {
          call.calls.forEach(function (c) {
            if (c.afterCallWork && c.afterCallWork.state == "pending") {
              isAcw = true;
              var currentAcw = new Date(c.afterCallWork.startTime);
              if (oldestAcw == null || currentAcw < oldestAcw) {
                oldestAcw = currentAcw;
                oldestAcwId = c.id;
                oldestAcwStringValue = c.afterCallWork.startTime;
              }
            }
          });
        }
        if (call.chats) {
          call.chats.forEach(function (c) {
            if (c.afterCallWork && c.afterCallWork.state == "pending") {
              isAcw = true;
              var currentAcw = new Date(c.afterCallWork.startTime);
              if (oldestAcw == null || currentAcw < oldestAcw) {
                oldestAcw = currentAcw;
                oldestAcwId = c.id;
                oldestAcwStringValue = c.afterCallWork.startTime;
              }
            }
          });
        }
        if (call.emails) {
          call.emails.forEach(function (c) {
            if (c.afterCallWork && c.afterCallWork.state == "pending") {
              isAcw = true;
              var currentAcw = new Date(c.afterCallWork.startTime);
              if (oldestAcw == null || currentAcw < oldestAcw) {
                oldestAcw = currentAcw;
                oldestAcwId = c.id;
                oldestAcwStringValue = c.afterCallWork.startTime;
              }
            }
          });
        }
        if (call.callbacks) {
          call.callbacks.forEach(function (c) {
            if (c.afterCallWork && c.afterCallWork.state == "pending") {
              isAcw = true;
              var currentAcw = new Date(c.afterCallWork.startTime);
              if (oldestAcw == null || currentAcw < oldestAcw) {
                oldestAcw = currentAcw;
                oldestAcwId = c.id;
                oldestAcwStringValue = c.afterCallWork.startTime;
              }
            }
          });
        }
        // if (call.startAcwTime && moment.utc(call.startAcwTime) < oldestAcw) {
        //     oldestAcw = moment.utc(call.startAcwTime);
        //     isAcw = true;
        // }

        var connectedDate = new Date(call.connectedTime);
        if (call.connectedTime && connectedDate < longestActive) {
          longestActive = connectedDate;
        }
        if (isAcw === false) {
          isAcw = call.startAcwTime && !call.endAcwTime;
        }
        var acwStartTime = new Date(call.startAcwTime);
        if (isAcw && acwStartTime < oldestAcw) {
          oldestAcw = acwStartTime;
        }
      });
      if (Object.keys(details.conversations).length > 1) {
        isAcw = false;
      }
      details.isAcw = isAcw;
      details.acwStartTime = isAcw ? oldestAcw.toISOString() : null;
      details.oldestAcwStringValue = oldestAcwStringValue;
      details.oldestAcwId = oldestAcwId;
      details.conversationCount = Object.keys(details.conversations).length;
      if (details.conversationCount > 0) {
        details.longestActiveStart = longestActive;
      } else {
        details.longestActiveStart = null;
      }
      if (details.conversationCount === 1 && isAcw) {
        var onlyConversation = details.conversations[Object.keys(details.conversations)[0]];
        var conversationAcwTime = onlyConversation.startAcwTime;
        if (conversationAcwTime) {
          details.oldestAcwStringValue = conversationAcwTime;
          details.participantAcwStartTime = details.acwStartTime;
          details.endTime = onlyConversation.endTime;
          details.acwStartTime = conversationAcwTime;
        }
      }
      userConversations[userId] = details;
      this.set("userConversations", userConversations);
    },
    processObservationData: function processObservationData() {
      var gatedData = this.get("gatedObservationData");
      var observationData = this.get("observationData");
      var queueIds = Object.keys(gatedData);
      for (var x = 0; x < queueIds.length; x++) {
        var queueId = queueIds[x];
        var queueData = gatedData[queueId];
        var groupIds = Object.keys(queueData);
        for (var y = 0; y < groupIds.length; y++) {
          var groupId = groupIds[y];
          var groupData = queueData[groupId];
          var keys = Object.keys(groupData);
          for (var z = 0; z < keys.length; z++) {
            var key = keys[z];
            var value = groupData[key];
            if (!observationData[queueId]) {
              observationData[queueId] = {};
            }
            if (!observationData[queueId][groupId]) {
              observationData[queueId][groupId] = {};
            }
            value.receivedBy = "poll";
            value.receivedAt = moment().format();
            observationData[queueId][groupId][key] = value;
          }
        }
      }
      this.set("observationData", observationData);
      this.trigger('observationData');
      this.set("gatedObservationData", {});
    },
    startPolling: function startPolling() {
      this.set("startPollingTime", moment());
      var observationPollingInterval = OBSERVATION_DATA_POLL;
      var self = this;
      function pollObservationData() {
        self.getObservationData();
        Ember.run.later(function () {
          pollObservationData();
        }, observationPollingInterval);
      }
      function pollUserStatusData() {
        self.getCurrentUserStatuses();
        Ember.run.later(function () {
          pollUserStatusData();
        }, 60000 * 2); //2 minutes
      }
      function realtimeGatedUpdate() {
        self.processObservationData();
        Ember.run.later(function () {
          realtimeGatedUpdate();
        }, REALTIME_GATED_UPDATE);
      }
      function poll() {
        self.getHistoricalData();
        self.getAgentStats();
        self.getCampaignData();
        var queueIds = self._getQueueIdsFromDashboards();
        var pollingInterval = self.get("pollingInterval");
        if (queueIds.length > 25) {
          pollingInterval = POLLING_INTERVAL * (queueIds.length / 25);
        }
        Ember.run.later(function () {
          poll();
          var webSocketOpen = self.get("webSocketOpenTime");
          if (moment().isAfter(webSocketOpen.add("22", "hours"))) {
            Ember.Logger.log("Reopening web socket");
            self._openWebSocket();
          }
        }, pollingInterval);
      }
      if (!this._isPollingHistorical) {
        this.set("_isPollingHistorical", true);
        Ember.run.later(function () {
          poll();
          pollObservationData();
          realtimeGatedUpdate();
          pollUserStatusData();
        }, 500);
      }
      this._openWebSocket();
    },
    _getCampaignIdsFromDashboards: function _getCampaignIdsFromDashboards() {
      var campaignList = [];
      this.dashboardConfiguration.dashboards.forEach(function (dashboard) {
        var widgets = dashboard.widgets;
        for (var w = 0; w < widgets.length; w++) {
          if (widgets[w].campaignId && widgets[w].campaignId.length > 0) {
            if (campaignList.indexOf(widgets[w].campaignId) === -1) {
              campaignList.push(widgets[w].campaignId);
            }
          }
        }
      });
      return campaignList;
    },
    _getQueueIdsFromDashboards: function _getQueueIdsFromDashboards() {
      var queueList = [];
      this.dashboardConfiguration.dashboards.forEach(function (dashboard) {
        var widgets = dashboard.widgets;
        for (var w = 0; w < widgets.length; w++) {
          if (widgets[w].widgetType === 'observationchart') {
            if (widgets[w].queueId) {
              queueList.push(widgets[w].queueId);
            }
            if (widgets[w].agentStatusQueue) {
              queueList.push(widgets[w].agentStatusQueue);
            }
            if (widgets[w].queueIds) {
              for (var q = 0; q < widgets[w].queueIds.length; q++) {
                var queueId = widgets[w].queueIds[q];
                if (queueList.indexOf(queueId) === -1) {
                  queueList.push(queueId);
                }
              }
            }
          } else if (widgets[w].widgetType !== 'agentstats') {
            for (var _q = 0; _q < widgets[w].queueIds.length; _q++) {
              var _queueId = widgets[w].queueIds[_q];
              if (queueList.indexOf(_queueId) === -1) {
                queueList.push(_queueId);
              }
            }
          }
        }
        if (dashboard.statgrid) {
          dashboard.statgrid.queueIds.forEach(function (queueId) {
            if (queueList.indexOf(queueId) === -1) {
              queueList.push(queueId);
            }
          });
        }
      });
      return queueList;
    },
    _getQueueFilter: function _getQueueFilter(queueIds) {
      var availableQueueIds = [];
      var queueFilter = [];
      if (!this.queueService.queueList) {
        return queueFilter;
      }
      this.queueService.queueList.forEach(function (q) {
        availableQueueIds.pushObject(q.id);
      });
      queueIds.forEach(function (id) {
        if (availableQueueIds.indexOf(id) === -1) {
          console.warn("user does not have access to queue " + id);
        } else if (id) {
          queueFilter.push({
            "type": "dimension",
            "dimension": "queueId",
            "operator": "matches",
            "value": id.trim()
          });
        }
      });
      return queueFilter;
    },
    _findWidgetMetric: function _findWidgetMetric(metricHandler) {
      var _this6 = this;
      this.dashboardConfiguration.dashboards.forEach(function (dashboard) {
        var widgets = dashboard.widgets;
        if (typeof widgets !== 'undefined' && widgets !== null) {
          for (var w = 0; w < widgets.length; w++) {
            var widget = widgets[w];
            if (widget.widgetType !== 'topwrapups' && widget.widgetType !== 'observationchart') {
              var config = _this6._getWidgetConfiguration(widget.type);
              if (typeof config !== 'undefined' && config !== null && typeof config.metrics !== "undefined") {
                for (var s = 0; s < config.metrics.length; s++) {
                  var metric = config.metrics[s];
                  metricHandler(metric, config.isObservation);
                }
              }
              if (typeof widget.stats !== "undefined") {
                for (var _s = 0; _s < widget.stats.length; _s++) {
                  var _config = _this6._getWidgetConfiguration(widget.stats[_s]);
                  if (typeof _config !== 'undefined' && _config !== null && typeof _config.metrics !== "undefined") {
                    for (var _s2 = 0; _s2 < _config.metrics.length; _s2++) {
                      var _metric = _config.metrics[_s2];
                      metricHandler(_metric, _config.isObservation);
                    }
                  }
                }
              }
            }
          }
        }
        if (dashboard.statgrid) {
          for (var _w = 0; _w < dashboard.statgrid.stats.length; _w++) {
            var _config2 = _this6._getWidgetConfiguration(dashboard.statgrid.stats[_w]);
            if (_config2.metrics) {
              for (var _s3 = 0; _s3 < _config2.metrics.length; _s3++) {
                var _metric2 = _config2.metrics[_s3];
                metricHandler(_metric2, _config2.isObservation);
              }
            }
          }
        }
      });
    },
    getObservationData: function getObservationData() {
      var queueIds = this._getQueueIdsFromDashboards();
      var queueFilter = this._getQueueFilter(queueIds);
      if (queueFilter.length === 0) {
        return;
      }
      var observationMetricList = ['oWaiting', 'oInteracting', 'oActiveUsers', 'oUserRoutingStatuses'];
      var observationDetailMetricList = ['oWaiting', 'oInteracting'];
      var analytics = this.get("purecloud").getAnalyticsApi();
      var self = this;
      if (observationMetricList.length > 0) {
        var promises = [];
        while (queueFilter.length > 99) {
          var requestData = {
            metrics: observationMetricList,
            detailMetrics: observationDetailMetricList,
            filter: {
              type: "or",
              predicates: queueFilter.slice(0, 99)
            }
          };
          queueFilter = queueFilter.slice(99);
          promises.pushObject(analytics.postQueuesObservationsQuery(requestData));
        }
        if (queueFilter.length > 0) {
          var _requestData = {
            metrics: observationMetricList,
            detailMetrics: observationDetailMetricList,
            filter: {
              type: "or",
              predicates: queueFilter
            }
          };
          promises.pushObject(analytics.postQueuesObservationsQuery(_requestData));
        }

        // can only query for 100 queues at a time

        Ember.RSVP.Promise.all(promises).then(function (queryResults) {
          var observationData = self.get("observationData");
          queryResults.forEach(function (queryResult) {
            var stats = queryResult.results;
            var _loop = function _loop(x) {
              var stat = stats[x];
              if (!observationData[stat.group.queueId]) {
                observationData[stat.group.queueId] = {};
              }
              var group = stat.group.mediaType;
              if (!group) {
                group = "queue";
              }
              if (!observationData[stat.group.queueId][group]) {
                observationData[stat.group.queueId][group] = {};
              }

              //POLL won't return interacting if no users are interacting, this can cause issues 
              // with not reseting back to 0 if we aren't getting WS notifications and need to fall back to poll
              // reset observations back to 0
              ['INTERACTING', 'IDLE', "OFF_QUEUE", "OFFLINE"].forEach(function (q) {
                stat.data.forEach(function (data) {
                  if (data.metric === "oUserRoutingStatues") {
                    var metricKey = "oUserRoutingStatuses" + "." + stat.data[x].qualifier;
                    observationData[stat.group.queueId][group][metricKey] = {
                      count: 0,
                      receivedBy: "poll reset",
                      receivedAt: moment().format()
                    };
                    observationData[stat.group.queueId][group][metricKey + "Observations"] = [];
                  }
                });
              });
              for (var _x = 0; _x < stat.data.length; _x++) {
                var metricKey = stat.data[_x].metric;
                if (stat.data[_x].qualifier) {
                  metricKey = metricKey + "." + stat.data[_x].qualifier;
                }
                stat.data[_x].stats.receivedBy = "poll";
                stat.data[_x].stats.receivedAt = moment().format();
                observationData[stat.group.queueId][group][metricKey] = stat.data[_x].stats;
                if (stat.data[_x].observations) {
                  observationData[stat.group.queueId][group][metricKey + "Observations"] = stat.data[_x].observations;
                } else {
                  observationData[stat.group.queueId][group][metricKey + "Observations"] = [];
                }
              }
            };
            for (var x = 0; x < stats.length; x++) {
              _loop(x);
            }
          });
          self.set("observationData", observationData);
          self.trigger('observationData');
        }).catch(function (err) {
          if (err.statusCode === 403) {
            self.get("missingPermissionService").reportMissing("analytics:queueObservation:view", err);
          }
          console.log(err);
          var message = "PureCloud API error - Queue Observation query " + JSON.stringify(err) + "   Metrics: " + JSON.stringify(observationMetricList) + " Queues: " + JSON.stringify(self._getQueueIdsFromDashboards());
          self.get("requestService").logError(message);
        });
      }
    },
    getAgentStats: function getAgentStats() {
      var dayStart = moment().startOf('day').utc().toISOString();
      var dayEnd = moment().startOf('day').add(1, 'd').utc().toISOString();
      var self = this;
      this.getAgentStatsWithStartEnd(dayStart, dayEnd, "P1D").then(function (agentData) {
        if (agentData.results.length > 0) {
          self.set("agentData", agentData);
        }
      });
    },
    getAgentStatsWithStartEnd: function getAgentStatsWithStartEnd(start, end, period) {
      var _this7 = this;
      return new Ember.RSVP.Promise(function (resolve) {
        var queueList = [];
        var widgetTimePeriod = "day";
        if (period === "PT30M") {
          widgetTimePeriod = "interval";
        }
        var metricList = [];
        _this7.dashboardConfiguration.dashboards.forEach(function (dashboard) {
          var widgets = dashboard.widgets;
          for (var w = 0; w < widgets.length; w++) {
            if (widgets[w].widgetType === 'agentstats') {
              for (var q = 0; q < widgets[w].queueIds.length; q++) {
                var queueId = widgets[w].queueIds[q];
                if (queueList.indexOf(queueId) === -1) {
                  queueList.push(queueId);
                }
              }
              var _loop2 = function _loop2() {
                var statId = widgets[w].stats[_q2];
                _agentstattypes.default.forEach(function (agentStat) {
                  if (statId === agentStat.key) {
                    agentStat.metrics.forEach(function (metric) {
                      if (metricList.indexOf(metric) === -1 && metric !== "") {
                        metricList.push(metric);
                      }
                    });
                  }
                });
              };
              for (var _q2 = 0; _q2 < widgets[w].stats.length; _q2++) {
                _loop2();
              }
            }
          }
        });
        var queueFilter = _this7._getQueueFilter(queueList);

        //debugger;
        if (queueFilter.length === 0 && metricList.length === 0) {
          resolve({
            results: []
          });
          return;
        }
        var analytics = _this7.get("purecloud").getAnalyticsApi();
        var self = _this7;
        if (metricList.length > 0) {
          var promises = [];
          while (queueFilter.length > 0) {
            var queueSubset = queueFilter.splice(0, 20);
            var requestData = {
              interval: start + "/" + end,
              groupBy: ["queueId", "userId"],
              metrics: metricList,
              granularity: period,
              filter: {
                type: "or",
                predicates: queueSubset
              }
            };
            promises.push(analytics.postConversationsAggregatesQuery(requestData));
          }
          Ember.RSVP.Promise.all(promises).then(function (queueDataArray) {
            var queueData = {
              results: []
            };
            for (var x = 0; x < queueDataArray.length; x++) {
              if (typeof queueDataArray[x].results !== 'undefined') {
                queueData.results = queueData.results.concat(queueDataArray[x].results);
              }
            }
            resolve(queueData);
          }).catch(function (err) {
            if (err.statusCode === 403) {
              self.get("missingPermissionService").reportMissing("analytics:conversationAggregate:view", err);
            }
            if (err.statusCode === 0) {
              self.set("pollingInterval", LONG_POLLING_INTERVAL);
            }
            Ember.Logger.warn(err);
            var message = "PureCloud API error - Conversation Aggregate query " + JSON.stringify(err) + "   Metrics: " + JSON.stringify(metricList) + " Queues: " + JSON.stringify(self._getQueueIdsFromDashboards());
            self.get("requestService").logError(message);
          });
        }
      });
    },
    getHistoricalData: function getHistoricalData() {
      var dayStart = moment().startOf('day').utc().toISOString();
      var dayEnd = moment().startOf('day').add(1, 'd').utc().toISOString();
      var self = this;
      this.getHistoricalDataWithStartEnd(dayStart, dayEnd, "P1D").then(function (queueData) {
        if (queueData.results.length > 0) {
          self.set("queueData", queueData);
        }
      });
      var currentIntervalStart = moment().startOf('hour');
      if (moment().minute() >= 30) {
        currentIntervalStart = currentIntervalStart.add(30, 'm');
      }
      var previousIntervalStart = currentIntervalStart.subtract(30, 'm').utc().toISOString();
      var queryEndTime = currentIntervalStart.add(1, 'h').utc().toISOString();
      currentIntervalStart = currentIntervalStart.utc().toISOString();
      this.getHistoricalDataWithStartEnd(previousIntervalStart, queryEndTime, "PT30M").then(function (queueData) {
        if (queueData.results.length > 0) {
          self.set("intervalQueueData", queueData);
        }
      });
    },
    getHistoricalDataWithStartEnd: function getHistoricalDataWithStartEnd(start, end, period) {
      var _this8 = this;
      return new Ember.RSVP.Promise(function (resolve) {
        var queueList = [];
        var widgetTimePeriod = "day";
        if (period === "PT30M") {
          widgetTimePeriod = "interval";
        }
        var metricList = [];
        _this8.dashboardConfiguration.dashboards.forEach(function (dashboard) {
          if (dashboard.statgrid && widgetTimePeriod === "day") {
            for (var q = 0; q < dashboard.statgrid.queueIds.length; q++) {
              var queueId = dashboard.statgrid.queueIds[q];
              if (queueList.indexOf(queueId) === -1) {
                queueList.push(queueId);
              }
            }
          } else {
            var widgets = dashboard.widgets;
            for (var w = 0; w < widgets.length; w++) {
              var timeperiod = widgets[w].timePeriod || "day";
              if (widgets[w].widgetType !== 'agentstats' && timeperiod.indexOf(widgetTimePeriod) > -1) {
                for (var _q3 = 0; _q3 < widgets[w].queueIds.length; _q3++) {
                  var _queueId2 = widgets[w].queueIds[_q3];
                  if (queueList.indexOf(_queueId2) === -1) {
                    queueList.push(_queueId2);
                  }
                }
              }
            }
          }
        });
        var queueFilter = _this8._getQueueFilter(queueList);

        //debugger;
        if (queueFilter.length === 0) {
          resolve({
            results: []
          });
          return;
        }
        _this8._findWidgetMetric(function (metric, isObservationStat) {
          if (!isObservationStat) {
            if (metricList.indexOf(metric) === -1) {
              metricList.push(metric);
            }
          }
        });
        var analytics = _this8.get("purecloud").getAnalyticsApi();
        var self = _this8;
        if (metricList.length > 0) {
          var promises = [];
          while (queueFilter.length > 0) {
            var queueSubset = queueFilter.splice(0, 20);
            var requestData = {
              interval: start + "/" + end,
              groupBy: ["queueId"],
              metrics: metricList,
              granularity: period,
              filter: {
                type: "or",
                predicates: queueSubset
              }
            };
            // debugger;
            promises.push(analytics.postConversationsAggregatesQuery(requestData));
          }
          Ember.RSVP.Promise.all(promises).then(function (queueDataArray) {
            var queueData = {
              results: []
            };
            for (var x = 0; x < queueDataArray.length; x++) {
              if (typeof queueDataArray[x].results !== 'undefined') {
                queueData.results = queueData.results.concat(queueDataArray[x].results);
              }
            }
            resolve(queueData);
          }).catch(function (err) {
            if (err.statusCode === 403) {
              self.get("missingPermissionService").reportMissing("analytics:conversationAggregate:view", err);
            }
            if (err.statusCode === 0) {
              self.set("pollingInterval", LONG_POLLING_INTERVAL);
            }
            Ember.Logger.warn(err);
            var message = "PureCloud API error - Conversation Aggregate query " + JSON.stringify(err) + "   Metrics: " + JSON.stringify(metricList) + " Queues: " + JSON.stringify(self._getQueueIdsFromDashboards());
            self.get("requestService").logError(message);
          });
        }
      });
    },
    getCampaignData: function getCampaignData() {
      var campaigns = this._getCampaignIdsFromDashboards();
      if (campaigns.length === 0) {
        return;
      }
      var outbound = this.get("purecloud").getOutboundApi();
      var self = this;
      var promiseList = [];
      campaigns.forEach(function (campaignId) {
        promiseList.push(outbound.getCampaignsCampaignIdStats(campaignId));
      });
      outbound.postCampaignsProgress(campaigns).then(function (progressData) {
        var campaignData = {};
        for (var x = 0; x < progressData.length; x++) {
          var data = progressData[x];
          campaignData[data.campaign.id] = data;
        }
        self.set("campaignProgress", campaignData);
      });
      Ember.RSVP.Promise.all(promiseList).then(function (campaignStats) {
        var campaignData = {};
        for (var x = 0; x < campaigns.length; x++) {
          var campaignId = campaigns[x];
          campaignData[campaignId] = campaignStats[x];
        }
        self.set("campaignStats", campaignData);
      }).catch(function (err) {
        if (err.statusCode === 403) {
          self.get("missingPermissionService").reportMissing("outbound:campaign:view", err);
        }
      });
    },
    getPageOfQueueUsers: function (_getPageOfQueueUsers) {
      function getPageOfQueueUsers(_x2, _x3, _x4, _x5) {
        return _getPageOfQueueUsers.apply(this, arguments);
      }
      getPageOfQueueUsers.toString = function () {
        return _getPageOfQueueUsers.toString();
      };
      return getPageOfQueueUsers;
    }(function (mainResolve, users, queueId, page) {
      var _this9 = this;
      this.get("purecloud").getRequest("/api/v2/routing/queues/".concat(queueId, "/members?pageNumber=").concat(page, "&pageSize=250&expand=routingStatus,presence,outOfOffice")).then(function (entities) {
        if (entities.entities.length > 0) {
          users.pushObjects(entities.entities);
        }
        if (entities.nextUri) {
          _this9.getPageOfQueueUsers(mainResolve, users, queueId, page + 1);
        } else {
          var res = {
            "entities": users
          };
          _this9.get("_queueUserCache")[queueId] = res;
          mainResolve(res);
        }
      }).catch(function (ex) {
        if (ex.headers && ex.headers['retry-after']) {
          setTimeout(function () {
            getPageOfQueueUsers(mainResolve, users, queueId, page);
          }, (parseInt(ex.headers['retry-after']) + 1) * 1000);
        } else {
          mainResolve({
            "entities": users
          });
        }
      });
    }),
    getQueueUsers: function getQueueUsers(queueUsersResolve, queueId) {
      var _this10 = this;
      var users = [];
      new Ember.RSVP.Promise(function (resolve) {
        _this10.getPageOfQueueUsers(resolve, users, queueId, 1);
      }).then(function (users) {
        _this10.get("_queueUserCache")[queueId] = users;
        queueUsersResolve(users);
      });
    },
    getCurrentUserStatuses: function getCurrentUserStatuses() {
      var _this11 = this;
      var queues = [];
      this.dashboardConfiguration.dashboards.forEach(function (dashboard) {
        var widgets = dashboard.widgets;
        if (typeof widgets !== 'undefined' && widgets !== null) {
          for (var w = 0; w < widgets.length; w++) {
            if (widgets[w].widgetType === 'agentstats') {
              widgets[w].queueIds.forEach(function (queue) {
                if (queues.indexOf(queue) === -1) {
                  queues.push(queue);
                }
              });
            }
          }
        }
      });
      var userImages = {};
      var userPresence = {};
      var userRoutingStatus = {};
      var userIsOOO = {};
      var queueUsers = this.get("queueUsers");
      if (!queueUsers) {
        queueUsers = {};
      }
      var self = this;
      var queuePromises = [];
      queues.forEach(function (queueId) {
        queuePromises.push(new Ember.RSVP.Promise(function (resolve) {
          _this11.getQueueUsers(resolve, queueId);
        }));
      });
      var userIds = [];
      Ember.RSVP.Promise.all(queuePromises).then(function (promiseResults) {
        var _loop3 = function _loop3() {
          var users = promiseResults[x];
          var queueId = queues[x];
          if (!queueUsers[queueId] || users.entities && users.entities.length > 0) {
            queueUsers[queueId] = [];
          }
          users.entities.forEach(function (user) {
            //unknown users are deleted users
            if (user.user.id.indexOf("UNKNOWN") === -1) {
              if (userIds.indexOf(user.id) === -1) {
                userIds.push(user.id);
              }
              queueUsers[queueId].push({
                id: user.id,
                name: user.name,
                joined: user.joined
              });
              if (user.user.images) {
                user.user.images.forEach(function (image) {
                  if (image.resolution === 'x48') {
                    userImages[user.id] = image.imageUri;
                  }
                });
              } else {
                userImages[user.id] = "https://dhqbrvplips7x.cloudfront.net/directory/4524/assets/images/svg/person.svg";
              }
              if (!user.user.presence) {
                user.user.presence = {
                  "source": "PURECLOUD",
                  "presenceDefinition": {
                    "systemPresence": ""
                  },
                  "message": "",
                  "modifiedDate": moment().format()
                };
              }
              user.user.presence.receivedBy = "poll";
              user.user.presence.receivedAt = moment().format();
              userPresence[user.id] = user.user.presence;
              user.routingStatus.receivedBy = "poll";
              user.routingStatus.receivedAt = moment().format();
              userRoutingStatus[user.id] = user.routingStatus;
              userIsOOO[user.id] = false;
              if (user.user && user.user.outOfOffice) {
                userIsOOO[user.id] = user.user.outOfOffice.active;
              }
            }
          });
        };
        for (var x = 0; x < promiseResults.length; x++) {
          _loop3();
        }
        self.set("queuePromises", queuePromises);
        self.set("userIsOOO", userIsOOO);
        self.set("userRoutingStatus", userRoutingStatus);
        self.set("userPresence", userPresence);
        self.set("userImages", userImages);
        self.set("queueUsers", queueUsers);
      });
    },
    getUserData: function getUserData() {
      var _this12 = this;
      var self = this;
      var queues = [];
      var queueUsers = {};
      var queuePromises = [];
      this.getCurrentUserStatuses();
      this.dashboardConfiguration.dashboards.forEach(function (dashboard) {
        var widgets = dashboard.widgets;
        if (typeof widgets !== 'undefined' && widgets !== null) {
          for (var w = 0; w < widgets.length; w++) {
            if (widgets[w].widgetType === 'agentstats') {
              widgets[w].queueIds.forEach(function (queue) {
                if (queues.indexOf(queue) === -1) {
                  queues.push(queue);
                }
              });
            }
          }
        }
      });
      queues.forEach(function (queueId) {
        queueUsers[queueId] = [];
        queuePromises.push(new Ember.RSVP.Promise(function (resolve) {
          if (_this12.get("_queueUserCache")[queueId]) {
            resolve(_this12.get("_queueUserCache")[queueId]);
            return;
          }
          _this12.getQueueUsers(resolve, queueId);
        }));
      });
      var userIds = [];
      Ember.RSVP.Promise.all(queuePromises).then(function (promiseResults) {
        _this12.getCurrentUserStatuses();
        for (var x = 0; x < promiseResults.length; x++) {
          var users = promiseResults[x];
          users.entities.forEach(function (user) {
            //unknown users are deleted users
            if (user.user.id.indexOf("UNKNOWN") === -1) {
              if (userIds.indexOf(user.id) === -1) {
                userIds.push(user.id);
              }
            }
          });
        }
        var channelId = self.get("websocketChannelId");
        var notificationsapi = self.get("purecloud").getNotificationsApi();
        Ember.run.later(function () {
          userIds.forEach(function (id) {
            var topic = "v2.users.".concat(id, ".presence");
            if (self.notificationSubscriptions.indexOf(topic) === -1) {
              self.notificationSubscriptions.push("v2.users.".concat(id, "?presence&routingStatus&outofoffice&conversations"));
            }
          });
          self.queryExistingConversationsForUsers(userIds);
          self.subscribeToTopicsWithRetry(channelId);
        }, 500);
      }).catch(function (err) {
        if (err.statusCode === 403) {
          self.get("missingPermissionService").reportMissing("routing:queue:view", err);
        }
      });
    },
    saveDashboards: function saveDashboards(dashboards) {
      //this.get("widgetStateService").clear();
      this.startPolling();
      dashboards.dashboards.forEach(function (dashboard) {
        dashboard.widgets.forEach(function (widget) {
          if (typeof widget.title === "undefined" || widget.title === null || widget.title.length === 0) {
            Ember.set(widget, "title", " ");
          }
        });
      });
      this.set('dashboardConfigurationChangedToggle', !this.get("dashboardConfigurationChangedToggle"));
      return this.get("requestService").postResource('/dashboards', dashboards);
    },
    dashboardConfigurationChangedToggle: false,
    hashCode: function hashCode(str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        var char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
      }
      return hash;
    },
    getDashboards: function getDashboards() {
      var self = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var requestService = self.get("requestService");
        requestService.getResource('/dashboards').then(function (dashboards) {
          var existingDashboardHash = self.get("dashboardHash");
          var newDashboardHash = self.hashCode(JSON.stringify(dashboards));
          if (existingDashboardHash === newDashboardHash) {
            console.log("dashboard is unchanged");
            resolve(self.dashboardConfiguration);
            return;
          } else {
            console.log("New dashboard configuration");
            self.set("dashboardHash", newDashboardHash);
          }
          var dashboardConfig = dashboards;
          if (Array.isArray(dashboards)) {
            dashboardConfig = {
              dashboards: dashboards
            };
            if (dashboards.length > 0) {
              dashboardConfig.darkMode = dashboards[0].darkMode;
            }
          }

          //Do some verification that each widget does not overlap and has an x/y
          function hasXOverlap(a, b) {
            return a.x + a.width <= b.x;
          }
          function hasYOverlap(a, b) {
            return a.y + a.height <= b.y;
          }
          for (var d = 0; d < dashboardConfig.dashboards.length; d++) {
            var dashboard = dashboardConfig.dashboards[d];
            var locations = [];
            var toRemove = [];
            var maxX = 0;
            var maxY = 1;
            var _loop4 = function _loop4(w) {
              var widget = dashboard.widgets[w];
              var xy = "".concat(widget.x, "-").concat(widget.y);
              if (locations.indexOf(xy) > 0) {
                console.log("already has " + xy);
                self.get("errorService").logError("already has widget at " + xy);
                // widget.x = 100 + widget.x;
                // widget.y = 100 + widget.y;

                toRemove.push(w);
              } else if (typeof widget.x === "undefined" || typeof widget.y === "undefined") {
                console.log("XY undefined ".concat(widget.x, " ").concat(widget.y));
                self.get("errorService").logError("XY undefined ".concat(widget.x, " ").concat(widget.y));
                toRemove.push(w);
              } else {
                locations.push(xy);
                dashboard.widgets.forEach(function (otherW) {
                  if (otherW.x !== widget.x && otherW.y !== widget.y) {
                    if (hasXOverlap(w, otherW)) {
                      console.log("".concat(w, " found x overlap ").concat(widget.x, ",").concat(widget.width, "  ").concat(otherW.x, ",").concat(otherW.width, " "));
                      self.get("errorService").logError("".concat(w, " found x overlap ").concat(widget.x, ",").concat(widget.width, "  ").concat(otherW.x, ",").concat(otherW.width, " "));
                      widget.width = 1;
                    }
                    if (hasYOverlap(w, otherW)) {
                      console.log("".concat(w, " found Y overlap ").concat(widget.y, ",").concat(widget.height, " ").concat(otherW.y, ",").concat(otherW.height));
                      self.get("errorService").logError("".concat(w, " found Y overlap ").concat(widget.y, ",").concat(widget.height, " ").concat(otherW.y, ",").concat(otherW.height));
                      widget.height = 1;
                    }
                  }
                });
                if (maxX < widget.x + widget.width) {
                  maxX = widget.x + widget.width + 1;
                }
                if (maxY < widget.y + widget.height) {
                  maxY = widget.y + widget.height + 1;
                }
              }
            };
            for (var w = 0; w < dashboard.widgets.length; w++) {
              _loop4(w);
            }
            for (var i = toRemove.length - 1; i >= 0; i--) {
              var wIndex = toRemove[i];
              console.log("adjusting ".concat(wIndex));
              self.get("errorService").logError("adjusting ".concat(wIndex));
              dashboard.widgets[wIndex].x = maxX + 1;
              dashboard.widgets[wIndex].width = 1;
              dashboard.widgets[wIndex].height = 2;
              dashboard.widgets[wIndex].y = i * 2;
            }
          }
          if (dashboardConfig.dashboards.length < 1 || typeof dashboardConfig.dashboards[0].dashboardName === "undefined") {
            dashboardConfig = {
              dashboards: [{
                dashboardName: "Default Dashboard",
                widgets: [],
                allowresize: true,
                id: _emberCliGuid.default.create()
              }]
            };
          }
          self.startPolling();
          self.set("dashboardConfiguration", dashboardConfig);
          self.dashboardConfiguration.dashboards.forEach(function (dashboard) {
            if (typeof dashboard.id === "undefined" || dashboard.id === null) {
              dashboard.id = _emberCliGuid.default.create();
            }
            var size = 4;
            if (dashboard.widgetSize === "md") {
              size = 3;
            } else if (dashboard.widgetSize === "sm") {
              size = 2;
            }
            var x = 0;
            var y = 0;
            if (dashboard.widgets) {
              for (var _i = 0; _i < dashboard.widgets.length; _i++) {
                if (dashboard.widgets[_i].widgetType === "undefined" || dashboard.widgets[_i].widgetType === null) {
                  dashboard.widgets[_i].widgetType = "statistic";
                  dashboard.widgets[_i].height = size;
                  dashboard.widgets[_i].width = size;
                  dashboard.widgets[_i].x = (size + 1) * _i % 12;
                  dashboard.widgets[_i].y = y;
                  x = x + size;
                }
              }
            }
          });
          self.set('dashboardConfigurationChangedToggle', !self.get("dashboardConfigurationChangedToggle"));
          resolve(self.dashboardConfiguration);
        }).catch(function (ex) {
          reject(ex);
        });
      });
    }
  });
});