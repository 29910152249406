define("insights-web/components/realtime-grid", ["exports", "insights-web/utils/widgetstattypes", "insights-web/utils/agentstattypes", "insights-web/utils/widgettypes", "insights-web/utils/stattimeperiods", "insights-web/utils/colorDefaults", "insights-web/utils/graphconfigoptions", "ember-cli-guid", "insights-web/config/environment"], function (_exports, _widgetstattypes, _agentstattypes, _widgettypes, _stattimeperiods, _colorDefaults, _graphconfigoptions, _emberCliGuid, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var STORAGE_ACTIVE_TAB_KEY = "pureInsightsActiveTab";
  var HEADLESS_COOKIE_NAME = "pureinsightsHeadless";
  var _default = _exports.default = Ember.Component.extend({
    cookies: Ember.inject.service(),
    queueService: Ember.inject.service(),
    featuretoggleService: Ember.inject.service(),
    statusService: Ember.inject.service(),
    campaignService: Ember.inject.service(),
    realtimeService: Ember.inject.service(),
    requestService: Ember.inject.service(),
    errorService: Ember.inject.service(),
    analyticsService: Ember.inject.service(),
    storageService: Ember.inject.service(),
    timeDiff: Ember.inject.service(),
    missingPermissionService: Ember.inject.service(),
    purecloud: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    embeddedService: Ember.inject.service(),
    graphConfigOptions: _graphconfigoptions.default,
    availableAgentStatTypes: [],
    agentStatusSort: ["Name", "Status", "Duration"],
    slaMediaTypes: ["All", "Voice", "Email", "Chat", "Callback", 'Message'],
    fontJustificationOptions: ["Left", "Center", "Right"],
    statTimePeriods: _stattimeperiods.default,
    isDashboardLayoutLocked: false,
    isHeadlessCookiePresent: function isHeadlessCookiePresent() {
      var cookieService = this.get('cookies');
      var cookie = JSON.parse(cookieService.read("purecloudTokenV2"));
      return cookie[HEADLESS_COOKIE_NAME];
    },
    isHeadlessSession: function isHeadlessSession() {
      return this.purecloud.getSessionId() === null;
    },
    rotateDashboards: function rotateDashboards() {
      var config = this.get("dashboardConfiguration");
      if (config.rotateDashboards === true) {
        var activeTab = this.get("activeTabId");
        var nextTabId = this.dashboardConfiguration.dashboards[0].id;
        for (var x = 0; x < this.dashboardConfiguration.dashboards.length - 1; x++) {
          if (this.dashboardConfiguration.dashboards[x].id === activeTab) {
            nextTabId = this.dashboardConfiguration.dashboards[x + 1].id;
          }
        }
        this.set('activeTabId', nextTabId);
      }
      var interval = 10000;
      if (config.rotateSeconds && config.rotateSeconds > 0) {
        interval = config.rotateSeconds * 1000;
      }

      //Ember.run.later(this.rotateDashboards, interval);
      Ember.run.later(this, function () {
        this.rotateDashboards();
      }, interval);
    },
    isStatGrid: Ember.computed('activeTabId', function () {
      var is = false;
      if (this.dashboardConfiguration.dashboards) {
        is = typeof this.dashboardConfiguration.dashboards[this.get("currentDashboardIndex")].statgrid !== "undefined" && this.dashboardConfiguration.dashboards[this.get("currentDashboardIndex")].statgrid !== null;
      }
      return is;
    }),
    selectedDashboardObserver: Ember.observer('activeTabId', function () {
      if (this.dashboardConfiguration.dashboards) {
        for (var x = 0; x < this.dashboardConfiguration.dashboards.length; x++) {
          var dashboard = this.dashboardConfiguration.dashboards[x];
          if (dashboard.id === this.get("activeTabId")) {
            this.set("currentDashboardIndex", x);
            return;
          }
        }
        this.set("currentDashboardIndex", 0);
        this.set('activeTabId', this.dashboardConfiguration.dashboards[0].id);
      }
    }),
    queueNameObserver: Ember.observer('queueService.queueList', 'queueService.@each', function () {
      this.set("queues", this.get("queueService").queueList);
    }),
    campaignNameObserver: Ember.observer('campaignService.campaignList', 'campaignService.@each', function () {
      this.set("campaigns", this.get("campaignService").campaignList);
    }),
    dashboardConfigurationObserver: Ember.observer('realtimeService.dashboardConfiguration', function () {
      var dashboards = this.get('realtimeService').get("dashboardConfiguration");
      this.set('activeTabId', dashboards.dashboards[0].id);
      this.set("dashboardConfiguration", dashboards);
    }),
    _widgetStatChange: Ember.observer('editWidget', 'editWidget.widgetType', function () {
      var _this = this;
      var widgetType = this.get("editWidget");
      if (widgetType === null) {
        return;
      }
      if (!widgetType.defaultTextColor) {
        if (this.dashboardConfiguration.darkMode) {
          widgetType.defaultTextColor = _colorDefaults.default.defaultDarkText;
        } else {
          widgetType.defaultTextColor = _colorDefaults.default.defaultText;
        }
      }
      if (!widgetType.defaultBackgroundColor) {
        if (this.dashboardConfiguration.darkMode) {
          widgetType.defaultBackgroundColor = _colorDefaults.default.defaultDarkBackground;
        } else {
          widgetType.defaultBackgroundColor = _colorDefaults.default.defaultBackground;
        }
      }
      if (!widgetType.titleAlign) {
        widgetType.titleAlign = "Left";
      }
      _widgetstattypes.default.forEach(function (stat) {
        if (widgetType.type === stat.key) {
          _this.set("selectedWidgetStatType", stat);
        }
      });
    }),
    _newWidgetTypeChange: Ember.observer('editWidget.type', function () {
      if (this.get("editWidget") === null) {
        this.set("allowZeroTimer", false);
        this.set("isCampaignStat", false);
        return;
      }
      var type = this.get("editWidget").type;
      var statTypes = this.get("statTypes");
      var allowZeroTimer = false;
      var isCampaignStat = false;
      for (var x = 0; x < statTypes.length; x++) {
        if (!allowZeroTimer && statTypes[x].key === type && statTypes[x].allowZeroTimer) {
          allowZeroTimer = true;
        }
        if (!isCampaignStat && statTypes[x].key === type && statTypes[x].isCampaign) {
          isCampaignStat = true;
        }
      }
      if (!allowZeroTimer) {
        Ember.set(this, 'editWidget.zeroTimer', false);
      }
      this.set("allowZeroTimer", allowZeroTimer);
      this.set("isCampaignStat", isCampaignStat);
    }),
    init: function init() {
      var _this2 = this;
      this._super.apply(this, arguments);
      this.set("pageLoadTime", moment());
      this.get("queueService");
      this.set("queues", this.get("queueService").queueList);
      this.set("campaigns", this.get("campaignService").queueList);
      this.set('isEmbedded', this.get("embeddedService").isEmbedded());
      this.set("shareCopyRestricted", this.get("isEmbedded") && !!window.chrome);
      _widgetstattypes.default.forEach(function (type) {
        if (type.widgetType.indexOf("chart") === -1) {
          _this2.gridStatTypes.pushObject(type.text);
        }
      });
      _agentstattypes.default.forEach(function (type) {
        _this2.availableAgentStatTypes.pushObject(type.text);
      });
      var statTypes = {};
      _widgetstattypes.default.sort(function (a, b) {
        return a.text.localeCompare(b.text);
      });
      _widgetstattypes.default.forEach(function (stat) {
        if (typeof statTypes[stat.category] === "undefined") {
          statTypes[stat.category] = {
            groupName: stat.category,
            options: []
          };
        }
        statTypes[stat.category].options.push(stat);
      });
      this.set("statTypesPowerSelect", Object.values(statTypes));
      var self = this;
      this.set('windowDimensions', {
        outerWidth: window.outerWidth,
        outerHeight: window.outerHeight,
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight
      });
      this.loadDashboards();
      this.set("isFullscreen", false);
      this.set("isDarkTheme", false);
      var featuretoggleService = this.get("featuretoggleService");
      this.set("dashboardplusEnabled", featuretoggleService.featureEnabled("dashboardplus"));
      _widgettypes.default.forEach(function (w) {
        if (featuretoggleService.featureEnabled(w.feature)) {
          _this2.widgetTypes.pushObject(w);
        }
      });
      if (this.isHeadlessCookiePresent() || this.isHeadlessSession()) {
        this.set('isFullscreen', true);
        this.set('isHeadless', true);
        this.set('lockWidgets', 'yes');
      } else {
        this.set('isHeadless', false);
      }
      this.get("requestService").getResource("/permalink/current").then(function (link) {
        _this2.set("currentPermalink", link.url);
      });
    },
    loadDashboards: function loadDashboards() {
      var _this3 = this;
      var self = this;
      this.get("realtimeService").getDashboards().then(function (dashboards) {
        var storage = self.get("storageService");
        var savedActiveTab = storage.localStorageGet(STORAGE_ACTIVE_TAB_KEY);
        if (savedActiveTab) {
          self.set('activeTabId', savedActiveTab);
        } else {
          self.set('activeTabId', dashboards.dashboards[0].id);
        }
        self.set("dashboardConfiguration", null);
        self.set("dashboardConfiguration", dashboards);
        self.set("isDashboardLayoutLocked", dashboards.isLayoutLocked);
        Ember.run.later(_this3, function () {
          this.rotateDashboards();
        }, 1000);
      }).catch(function (err) {
        Ember.Logger.error(err);
        if (err.payload && err.payload.error === "invalid permalink") {
          window.location.replace('/loginerror.html?error=Invalid Permalink');
          return;
        }
        self.get('notifications').error('Unable to get dashboard configuration.', {
          autoClear: true,
          clearDuration: 1200
        });
        self.get("analyticsService").exception(err);
      });

      // Ember.run.later(this, function () {
      //     this.loadDashboards();
      // }, 60000);
    },
    statTypes: _widgetstattypes.default,
    widgetTypes: [],
    editWidget: null,
    editDashboard: null,
    currentDashboardIndex: 0,
    editStatGrid: null,
    gridStatTypes: [],
    selectedGridStatTypes: [],
    actions: {
      permalinkCopySuccess: function permalinkCopySuccess() {
        this.get('notifications').success('Link copied to clipboard', {
          autoClear: true,
          clearDuration: 1200
        });
      },
      selectTab: function selectTab(id) {
        var storage = this.get("storageService");
        storage.localStorageSet(STORAGE_ACTIVE_TAB_KEY, id);
        this.set("activeTabId", id);
      },
      deleteWidget: function deleteWidget(index) {
        this.dashboardConfiguration.dashboards[this.get("currentDashboardIndex")].widgets.removeAt(index);
        var self = this;
        this.get("realtimeService").saveDashboards(this.dashboardConfiguration).then(function () {
          self.get('notifications').success('Saved successfully', {
            autoClear: true,
            clearDuration: 1200
          });
        }).catch(function (err) {
          Ember.Logger.error(err);
          self.get("analyticsService").exception(err);
          self.get('notifications').error('Unable to save dashboard settings. Wait 90 seconds and make another dashboard edit to try again.', {
            autoClear: true,
            clearDuration: 1200
          });
        });
      },
      showAddWidget: function showAddWidget() {
        this.set("editWidget", {
          type: this.get("statTypes")[0].key,
          title: "",
          queueIds: [],
          alerts: [],
          stats: [],
          widgetType: "statistic"
        });
      },
      convertToResize: function convertToResize() {
        Ember.set(this.editDashboard, "allowresize", true);
      },
      showSettings: function showSettings() {
        this.set("shareCode", null);
        this.set("shareCodeSuccess", false);
        this.set("shareCodeFailure", false);
        this.set("loadShareCode", null);
        this.set("loadCodeFailure", false);
        var config = this.get("dashboardConfiguration");
        this.set("previousSettings", JSON.parse(JSON.stringify(config)));
        if (!config.rotateSeconds) {
          config.rotateSeconds = 10;
          this.set("dashboardConfiguration", config);
        }
        this.set("shouldShowSettings", true);
      },
      saveDashboardSettings: function saveDashboardSettings() {
        this.get("realtimeService").saveDashboards(this.dashboardConfiguration);
        this.set("shouldShowSettings", false);
      },
      cancelDashboardSettings: function cancelDashboardSettings() {
        this.set("shouldShowSettings", false);
        this.set("dashboardConfiguration", this.get("previousSettings"));
      },
      showEditWidget: function showEditWidget(index) {
        var _this4 = this;
        var widgetToEdit = JSON.parse(JSON.stringify(this.dashboardConfiguration.dashboards[this.get("currentDashboardIndex")].widgets[index]));

        //widgetToEdit.queue = widgetToEdit.queueIds[0];
        this.set("editWidget", this.set("editWidget", widgetToEdit));
        this.set("editWidgetIndex", index);
        _widgetstattypes.default.forEach(function (stat) {
          if (widgetToEdit.type === stat.key) {
            _this4.set("selectedWidgetStatType", stat);
          }
        });
        if (widgetToEdit.widgetType === "chart") {
          _graphconfigoptions.default.stats.forEach(function (stat) {
            if (widgetToEdit.type === stat.key) {
              _this4.set("selectedWidgetStatType", stat);
            }
          });
        }
        this.selectedGridStatTypes.clear();
        this.gridStatTypes.clear();
        this.availableAgentStatTypes.clear();
        if (widgetToEdit.widgetType === "queuestats") {
          widgetToEdit.stats.forEach(function (statKey) {
            _widgetstattypes.default.forEach(function (type) {
              if (statKey === type.key) {
                _this4.selectedGridStatTypes.pushObject(type.text);
              }
            });
          });
          _widgetstattypes.default.forEach(function (type) {
            if (widgetToEdit.stats.indexOf(type.key) === -1 && type.widgetType.indexOf("chart") === -1) {
              _this4.gridStatTypes.pushObject(type.text);
            }
          });
        } else if (widgetToEdit.widgetType === "agentstats") {
          widgetToEdit.stats.forEach(function (statKey) {
            _agentstattypes.default.forEach(function (type) {
              if (statKey === type.key) {
                _this4.selectedGridStatTypes.pushObject(type.text);
              }
            });
          });
          _agentstattypes.default.forEach(function (type) {
            if (widgetToEdit.stats.indexOf(type.key) === -1) {
              _this4.availableAgentStatTypes.pushObject(type.text);
            }
          });
        }
      },
      showEditDashboard: function showEditDashboard() {
        this.set("editDashboard", {
          dashboardName: "New Dashboard",
          widgets: [],
          allowresize: true,
          id: _emberCliGuid.default.create()
        });
      },
      deleteDashboard: function deleteDashboard(index) {
        this.set("confirmDelete", true);
        this.dashboardConfiguration.dashboards.removeAt(index);
        this.set('activeTabId', this.dashboardConfiguration.dashboards[0].id);
      },
      cancelEditDashboard: function cancelEditDashboard() {
        this.set("editDashboard", null);
      },
      saveDashboard: function saveDashboard() {
        var newDashboard = this.get("editDashboard");
        this.dashboardConfiguration.dashboards.addObject(newDashboard);
        this.set("editDashboard", null);
        var self = this;
        this.get("realtimeService").saveDashboards(this.dashboardConfiguration).then(function () {
          self.get('notifications').success('Saved successfully', {
            autoClear: true,
            clearDuration: 1200
          });
        }).catch(function (err) {
          self.get("analyticsService").exception(err);
          self.get('notifications').error('Unable to save dashboard.', {
            autoClear: true,
            clearDuration: 1200
          });
        });
      },
      addWidget: function addWidget() {
        this.get("analyticsService").logWidgetConfigure();
        var newWidget = this.get("editWidget");
        if (newWidget.widgetType !== "observationchart") {
          newWidget.stats = [];
          this.selectedGridStatTypes.forEach(function (selectedName) {
            if (newWidget.widgetType === "queuestats") {
              _widgetstattypes.default.forEach(function (type) {
                if (type.text === selectedName) {
                  newWidget.stats.push(type.key);
                }
              });
            } else if (newWidget.widgetType === "agentstats") {
              _agentstattypes.default.forEach(function (type) {
                if (type.text === selectedName) {
                  newWidget.stats.push(type.key);
                }
              });
            }
          });
        }
        if (typeof newWidget.title === "undefined" || newWidget.title === null) {
          Ember.set(newWidget, "title", "");
        }
        var selectedWidgetStatType = this.get("selectedWidgetStatType");
        if (selectedWidgetStatType !== null) {
          Ember.set(newWidget, "type", selectedWidgetStatType.key);
        }
        delete newWidget.queue;
        var editIndex = this.get("editWidgetIndex");
        if (!Ember.get(newWidget, "fontsize")) {
          Ember.set(newWidget, "fontsize", "18");
        }
        if (typeof newWidget.x === "undefined") {
          var maxX = 0;
          var maxY = 0;
          this.dashboardConfiguration.dashboards[this.get("currentDashboardIndex")].widgets.forEach(function (w) {
            var x = w.x + w.width;
            var y = w.y + w.height;
            if (x > maxX) {
              maxX = x;
            }
            if (y > maxY) {
              maxY = y;
            }
          });
          newWidget.x = maxX;
          newWidget.y = maxY;
          newWidget.height = 2;
          newWidget.width = 1;
        }
        this.set("editWidget", null);
        this.set("editWidgetIndex", null);
        if (typeof editIndex !== "undefined" && editIndex !== null) {
          this.dashboardConfiguration.dashboards[this.get("currentDashboardIndex")].widgets.replace(editIndex, 1, [newWidget]);
        } else {
          this.dashboardConfiguration.dashboards[this.get("currentDashboardIndex")].widgets.pushObject(newWidget);
        }

        // this.availableAgentStatTypes.clear();
        // agentStatTypes.forEach((type)=>{
        //     this.availableAgentStatTypes.pushObject(type.text);
        // });

        var self = this;
        this.get("realtimeService").saveDashboards(this.dashboardConfiguration).then(function () {
          self.get('notifications').success('Saved successfully', {
            autoClear: true,
            clearDuration: 1200
          });
        }).catch(function (err) {
          Ember.Logger.error(err);
          self.get("analyticsService").exception(err);
          self.get('notifications').error('Unable to save dashboard settings. Wait 90 seconds and make another dashboard edit to try again.', {
            autoClear: true,
            clearDuration: 1200
          });
        });
      },
      cancelAddWidget: function cancelAddWidget() {
        this.set("editWidget", null);
        this.set("editWidgetIndex", null);
      },
      addAlert: function addAlert() {
        this.editWidget.alerts.pushObject({
          min: 0,
          max: 100,
          color: "#ff0000"
        });
      },
      deleteAlert: function deleteAlert(index) {
        this.editWidget.alerts.removeAt(index);
      },
      toggleFullscreen: function toggleFullscreen() {
        var fullscreen = this.get("isFullscreen");
        this.set("isFullscreen", !fullscreen);
      },
      toggleDashboardLock: function toggleDashboardLock() {
        if (this.get('isDashboardLayoutLocked') === true) {
          this.set('isDashboardLayoutLocked', false);
        } else {
          this.set('isDashboardLayoutLocked', true);
        }
        this.dashboardConfiguration.isLayoutLocked = this.get("isDashboardLayoutLocked");
        this.get("realtimeService").saveDashboards(this.dashboardConfiguration);
      },
      saveStatGrid: function saveStatGrid() {
        var editStatGrid = this.get("editStatGrid");
        editStatGrid.stats = [];
        this.selectedGridStatTypes.forEach(function (selectedName) {
          _widgetstattypes.default.forEach(function (type) {
            if (type.text === selectedName) {
              editStatGrid.stats.push(type.key);
            }
          });
        });

        //this.dashboardConfiguration[this.get("currentDashboardIndex")].statgrid = editStatGrid;
        Ember.set(this.dashboardConfiguration.dashboards[this.get("currentDashboardIndex")], 'statgrid', editStatGrid);
        this.set("editStatGrid", null);
        var self = this;
        this.get("realtimeService").saveDashboards(this.dashboardConfiguration).then(function () {
          self.get('notifications').success('Saved successfully', {
            autoClear: true,
            clearDuration: 1200
          });
        }).catch(function (err) {
          self.get("analyticsService").exception(err);
          self.get('notifications').error('Unable to save dashboard.', {
            autoClear: true,
            clearDuration: 1200
          });
        });
      },
      deleteStatGrid: function deleteStatGrid() {
        Ember.set(this.dashboardConfiguration.dashboards[this.get("currentDashboardIndex")], 'statgrid', null);
        var self = this;
        this.get("realtimeService").saveDashboards(this.dashboardConfiguration).then(function () {
          self.get('notifications').success('Saved successfully', {
            autoClear: true,
            clearDuration: 1200
          });
        }).catch(function (err) {
          self.get("analyticsService").exception(err);
          self.get('notifications').error('Unable to save dashboard.', {
            autoClear: true,
            clearDuration: 1200
          });
        });
      },
      cancelEditStatGrid: function cancelEditStatGrid() {
        this.set("editStatGrid", null);
      },
      showEditGrid: function showEditGrid() {
        var _this5 = this;
        var widgetToEdit = this.dashboardConfiguration.dashboards[this.get("currentDashboardIndex")].statgrid;
        if (typeof widgetToEdit === "undefined" || widgetToEdit === null) {
          widgetToEdit = {
            queueIds: [],
            stats: []
          };
        }
        widgetToEdit = JSON.parse(JSON.stringify(widgetToEdit));
        this.selectedGridStatTypes.clear();
        this.gridStatTypes.clear();
        widgetToEdit.stats.forEach(function (statKey) {
          _widgetstattypes.default.forEach(function (type) {
            if (statKey === type.key) {
              _this5.selectedGridStatTypes.pushObject(type.text);
            }
          });
        });
        _widgetstattypes.default.forEach(function (type) {
          if (widgetToEdit.stats.indexOf(type.key) === -1 && type.widgetType.indexOf("chart") === -1) {
            _this5.gridStatTypes.pushObject(type.text);
          }
        });

        //widgetToEdit.queue = widgetToEdit.queueIds[0];
        this.set("editStatGrid", widgetToEdit);
      },
      gridChange: function gridChange(event, items) {
        var _this6 = this;
        if (this.isHeadless) {
          return;
        }
        if (this.isDashboardLayoutLocked) {
          return;
        }
        var dashboardIndex = this.get("currentDashboardIndex");
        items.forEach(function (item) {
          try {
            var id = parseInt(item.id);
            Ember.set(_this6.dashboardConfiguration.dashboards[dashboardIndex].widgets[id], 'height', item.height);
            Ember.set(_this6.dashboardConfiguration.dashboards[dashboardIndex].widgets[id], 'width', item.width);
            Ember.set(_this6.dashboardConfiguration.dashboards[dashboardIndex].widgets[id], 'x', item.x);
            Ember.set(_this6.dashboardConfiguration.dashboards[dashboardIndex].widgets[id], 'y', item.y);
          } catch (ex) {
            Ember.Logger.error(ex);
          }
        });
        var self = this;
        this.get("realtimeService").saveDashboards(this.dashboardConfiguration).catch(function (err) {
          Ember.Logger.error(err);
          self.get("analyticsService").exception(err);
          self.get('notifications').error('Unable to save dashboard settings. Wait 90 seconds and make another dashboard edit to try again.', {
            autoClear: true,
            clearDuration: 1200
          });
        });
      },
      moveDashboardUp: function moveDashboardUp(index) {
        if (index === 0) {
          return;
        }
        var indexDashboard = this.dashboardConfiguration.dashboards[index];
        this.dashboardConfiguration.dashboards.removeAt(index);
        this.dashboardConfiguration.dashboards.insertAt(index - 1, indexDashboard);
      },
      moveDashboardDown: function moveDashboardDown(index) {
        if (index === this.dashboardConfiguration.dashboards.length - 1) {
          return;
        }
        var indexDashboard = this.dashboardConfiguration.dashboards[index];
        this.dashboardConfiguration.dashboards.removeAt(index);
        this.dashboardConfiguration.dashboards.insertAt(index + 1, indexDashboard);
      },
      generatePermalink: function generatePermalink() {
        var _this7 = this;
        this.get("requestService").getResource("/permalink/create").then(function (link) {
          _this7.set("currentPermalink", link.url);
          var textArea = document.createElement("textarea");
          textArea.value = link.url;
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          try {
            var successful = document.execCommand('copy');
            if (successful) {
              _this7.get('notifications').success('Permalink copied to clipboard', {
                autoClear: true,
                clearDuration: 1200
              });
            } else {
              _this7.get('notifications').error('Unable to copy permalink to clipboard.', {
                autoClear: true,
                clearDuration: 1200
              });
            }
          } catch (err) {
            console.log.error(err);
            _this7.get('notifications').error('Unable to copy permalink to clipboard.', {
              autoClear: true,
              clearDuration: 1200
            });
          }
          document.body.removeChild(textArea);
          window.scrollTo(0, 0);
        }).catch(function (ex) {
          console.log.error(ex);
          _this7.get('notifications').error('Unable to generate permalink.', {
            autoClear: true,
            clearDuration: 1200
          });
        });
      },
      doLoadShareCode: function doLoadShareCode() {
        var code = this.get("loadShareCode");
        try {
          var strCode = atob(code);
          var config = JSON.parse(strCode);
          config.id = _emberCliGuid.default.create();
          this.dashboardConfiguration.dashboards.pushObject(config);
        } catch (ex) {
          console.error(ex);
          this.set("loadCodeFailure", true);
        }
      },
      shareDashboard: function shareDashboard(index) {
        var _this8 = this;
        var indexDashboard = JSON.stringify(this.dashboardConfiguration.dashboards[index]);
        this.set("shareCode", btoa(indexDashboard));
        if (this.get("shareCopyRestricted")) {
          this.set("shareCodeFailure", true);
          return;
        }
        try {
          if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(btoa(indexDashboard)).then(function () {
              _this8.set("shareCodeSuccess", true);
            }).catch(function (err) {
              console.log(err);
              _this8.shareDashboardLegacy(index);
            });
          } else {
            this.shareDashboardLegacy(index);
          }
        } catch (err) {
          console.log(err);
          this.set("shareCodeFailure", true);
        }
      },
      shareDashboardLegacy: function shareDashboardLegacy(index) {
        var textArea = document.createElement("textarea");
        try {
          var indexDashboard = JSON.stringify(this.dashboardConfiguration.dashboards[index]);
          this.set("shareCode", btoa(indexDashboard));
          textArea.value = btoa(indexDashboard);
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          var successful = document.execCommand('copy');
          if (successful) {
            this.set("shareCodeSuccess", true);
          } else {
            this.set("shareCodeFailure", true);
          }
        } catch (err) {
          console.log(err);
          this.set("shareCodeFailure", true);
        }
        document.body.removeChild(textArea);
        window.scrollTo(0, 0);
      },
      downloadDebug: function downloadDebug() {
        var _this9 = this;
        this.get("purecloud").getRequest("/api/v2/date").then(function (date) {
          _this9.get("requestService").getMe().then(function (me) {
            var data = {
              meta: "downloaded via menu option",
              currentTime: moment().format(),
              pollingStartTime: _this9.get('realtimeService').get("startPollingTime"),
              webSocketOpenTime: _this9.get('realtimeService').get("webSocketOpenTime"),
              pageLoadTime: _this9.get("pageLoadTime"),
              lastWebSocketNotification: _this9.get('realtimeService').get("lastWebSocketNotification"),
              queues: _this9.get("queueService").queueList,
              queueUsers: _this9.get('realtimeService').get("queueUsers"),
              dashboardConfig: _this9.get("dashboardConfiguration"),
              userPresence: _this9.get('realtimeService').get("userPresence"),
              userRoutingStatus: _this9.get('realtimeService').get("userRoutingStatus"),
              userConversations: _this9.get('realtimeService').get("userConversations"),
              queueData: _this9.get('realtimeService').get("queueData"),
              observationData: _this9.get('realtimeService').get("observationData"),
              lastUpdateTimes: _this9.get('realtimeService').get("lastUpdateTimes"),
              notificationHistory: _this9.get('realtimeService').get("notificationHistory"),
              latestErrors: _this9.get('errorService').get("recentErrors"),
              campaignStats: _this9.get('requestService').get("campaignStats"),
              agentData: _this9.get('requestService').get("agentData"),
              queueAggregates: _this9.get('realtimeService').get("queueAggregateHistory"),
              statusConfiguration: _this9.statusService.definations,
              me: me,
              date: date,
              window: _this9.get('windowDimensions'),
              skewInformation: _this9.get("timeDiff").skewInformation,
              browser: {
                appCodeName: navigator.appCodeName,
                appName: navigator.appName,
                appVersion: navigator.appVersion,
                languages: navigator.languages,
                oscpu: navigator.oscpu,
                userAgent: navigator.userAgent
              },
              build: {
                buildNumber: _environment.default.APP.BuildNumber,
                commit: _environment.default.APP.Commit
              },
              websocket: _this9.get('realtimeService').get("websocketDetails")
            };

            //
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(data)));
            element.setAttribute('download', "pureinsightsdebugdata.json");
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          });
        });
      }
    },
    dashboardConfiguration: [{
      dashboardName: "default dashboard",
      widgets: [],
      id: _emberCliGuid.default.create()
    }]
  });
});